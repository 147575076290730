<template>
    <div>

        <b-table   hover :items="periodic" :fields="['activity', 'description', 'frequency']" show-empty class="border">
            <template #cell(frequency)="data">
    
                <b-badge variant="primary">{{ formatFrequency(data.item.frequency) }}</b-badge>
            </template>
            <template #empty>
                <div
                        class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                        style="min-height: 180px;"
                    >
                        <feather-icon icon="MehIcon" size="36" />
                        <p class="mt-2 text-center w-75 text-muted">
                        It seems like there are no periodic activities available.
                        </p>
                </div>
            </template>
        </b-table>
        <!-- <div class="d-flex justify-content-end align-items-end">
            <b-pagination v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"/>
        </div> -->
    </div>

</template>
<script>
import { BBadge, BPagination, BTable } from 'bootstrap-vue';



export default {
   components:{
       BTable,
       BBadge,
       BPagination
   },
   data(){
       return {
           periodic:[],
           currentPage:1,
           total:0,
           perPage:10
       }
   },
   mounted(){
       this.load()
   },
   methods:{
       load(){
           this.getPeriodic(this.$route.params.controlId)
       },
       async getPeriodic(id){
           const options = {
               method: "GET",
               headers: { "content-type": "application/json" },
               url: process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts`,
               params:{control_id:id,page:this.currentPage,per_page:this.perPage}
           };
           try {
               const res=await this.$http(options)
               this.periodic = res.data.data.data;

           } catch (error) {
               console.log(error);
           }
       },
       formatFrequency(value) {
        const frequencyMap = {
            1: 'HOURLY',
            2: 'DAILY',
            3: 'WEEKLY',
            4: 'BIWEEKLY',
            5: 'MONTHLY',
            6: 'QUARTERLY',
            7: 'HALF-YEARLY',
            8: 'YEARLY'
        };
        return frequencyMap[value] || 'Unknown';
        }
   },
   watch:{
    currentPage(){
        this.getPeriodic(this.$route.params.controlId)
    }
   }
}
</script>
