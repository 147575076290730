<template>
    <div>
        <b-table v-if="questions.length" hover :items="questions" :fields="['question','answer','always_applicable']" show-empty class="border">
           <template #empty>
               <div
                       class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                       style="min-height: 180px;"
                   >
                       <feather-icon icon="MehIcon" size="36" />
                       <p class="mt-2 text-center w-75 text-muted">
                       It seems like there are no risks available.
                       </p>
               </div>
           </template>
        </b-table>

    </div>
</template>
<script>
import { BPagination, BTable } from 'bootstrap-vue';



export default {
    components:{
        BTable,
    },
    data(){
        return {
            questions:[],
            currentPage:1,
            total:0,
            perPage:10
        }
    },
    mounted(){
        this.load()
    },
    methods:{
        load(){
            this.getQuestions(this.$route.params.controlId)
        },
        async getQuestions(id){
            const options = {
                method: "GET",
                headers: { "content-type": "application/json" },
                url: process.env.VUE_APP_ONECSF_API_URL + `/assessment/${id}`,
            };
            try {
                const res=await this.$http(options)
                this.questions = Object.values(res.data.data);
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
