<template>
    <div>
        <div v-for="items in Object.keys(controlframework)" :key="items" class="border p-1" > 
            <p class="font-weight-bold">{{ items}}</p>
            <b-table   hover :items="controlframework[items]"  show-empty :key="controlframework[items].standard" :fields="['control_no','name','description','type']" class="border">
                <template #empty>
                    <div
                            class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                            style="min-height: 180px;"
                        >
                            <feather-icon icon="MehIcon" size="36" />
                            <p class="mt-2 text-center w-75 text-muted">
                            It seems like there are no control framework available.
                            </p>
                    </div>
                </template>
            </b-table>
        </div>
    </div>

</template>
<script>
import { BTable } from 'bootstrap-vue';



export default {
   components:{
       BTable
   },
   data(){
       return {
           controlframework:[]
       }
   },
   mounted(){
       this.load()
   },
   methods:{
       load(){
           this.getControlFramework(this.$route.params.controlId)
       },
       async getControlFramework(id){
           const options = {
               method: "GET",
               headers: { "content-type": "application/json" },
               url: process.env.VUE_APP_ONECSF_API_URL + `/controls/details/related/${id}`,
           };
           try {
               const res=await this.$http(options)
               this.controlframework = res.data.data;
           } catch (error) {
               console.log(error);
           }
       }
   }
}
</script>
