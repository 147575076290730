<template>
    <div>

        <b-table   hover :items="risks" :fields="['name', 'description', 'treatment_plan']" show-empty class="border">
            <template #empty>
                <div
                        class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                        style="min-height: 180px;"
                    >
                        <feather-icon icon="MehIcon" size="36" />
                        <p class="mt-2 text-center w-75 text-muted">
                        It seems like there are no risks available.
                        </p>
                </div>
            </template>
        </b-table>
        <div class="d-flex justify-content-end align-items-end">
            <b-pagination v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"/>
        </div>
    </div>

</template>
<script>
import { BPagination, BTable } from 'bootstrap-vue';



export default {
   components:{
       BTable,
       BPagination
   },
   data(){
       return {
           risks:[],
           currentPage:1,
           total:0,
           perPage:10
       }
   },
   mounted(){
       this.load()
   },
   methods:{
       load(){
           this.getRisks(this.$route.params.controlId)
       },
       async getRisks(id){
           const options = {
               method: "GET",
               headers: { "content-type": "application/json" },
               url: process.env.VUE_APP_ONECSF_API_URL + `/controls/details/${id}/${this.$route.params.id}/risks`,
               params:{page:this.currentPage}
           };
           try {
               const res=await this.$http(options)
               this.risks = res.data.data.data;
               this.total=res.data.data.total
           } catch (error) {
               console.log(error);
           }
       }
   },
   watch:{
    currentPage(){
        this.getRisks(this.$route.params.controlId)
    }
   }
}
</script>
