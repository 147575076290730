<template>
    <div>
        <div class="card m-1">
            <div class="p-1">
                <h4>{{control.control_no}} : {{ control.name }}</h4>
                <p >{{ control.description }}</p>
            </div>
        </div>
        <div class="card m-1">
            <div class="p-1">
                <b-tabs content-class="m-1" pills lazy>
                    <b-tab title="Risks Mapped"  >
                        <control-risks/>
                    </b-tab>
                    <b-tab v-if="isPrimary" title="Mapped Frameworks and Controls">
                        <control-framework/>
                    </b-tab>
                    <!-- <b-tab title="Mapped Policies">
                        <div>
                            <b-table  hover :items="items"></b-table>
                        </div>
                    </b-tab> -->
                    <b-tab title="Mapped Periodic Activites">
                        <control-periodic-activities/>
                    </b-tab>
                    <b-tab title="Mapped Questions" >
                        <control-questions/>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import { BTab, BTable, BTabs } from 'bootstrap-vue';
import ControlQuestions from '@/views/controls/section/ControlQuestions.vue';
import ControlRisks from './controls/section/ControlRisks.vue';
import ControlFramework from './controls/section/ControlFramework.vue';
import ControlPeriodicActivities from './controls/section/ControlPeriodicActivities.vue';
export default{
    data(){
        return {
            control:null,
            standard:null,
            items: [
          { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
          { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
          { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
          { age: 38, first_name: 'Jami', last_name: 'Carney' }
          ]
        }
    },
    components:{
        BTabs,
        BTab,
        BTable,
        ControlQuestions,
        ControlRisks,
        ControlFramework,
        ControlPeriodicActivities
    },
    computed:{
        isPrimary(){
            return this.standard?.is_primary===1
        }
    },
    mounted(){
        this.load()
    },
    methods:{
        load(){
            this.getcontrolDetails()
            this.getStandard()
        },
        async getcontrolDetails(){
            const options = {
                method: "GET",
                headers: { "content-type": "application/json" },
                url: process.env.VUE_APP_ONECSF_API_URL + `/controls/${this.$route.params.controlId}`,
            };
            try {
                const res=await this.$http(options)
                this.control = res.data.data.control;
                console.log('control',this.control)
            } catch (error) {
                console.log(error);
            }
        },
        async getStandard(){
            const options = {
                method: "GET",
                headers: { "content-type": "application/json" },
                url: process.env.VUE_APP_ONECSF_API_URL + `/standards/${this.$route.params.id}`,
            };
            try {
                const res=await this.$http(options)
                this.standard = res.data.data.standard;
                console.log('control',this.control)
            } catch (error) {
                console.log(error);
            }
        }
    }

}
</script>